import * as type from "./type";

const initialState = {
  ticketApiRepsonse: null,
  adminTicket: null,
};

const SupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_TICKETS_REQUEST:
      return { ...state, ticketApiRepsonse: null };
    case type.GET_TICKETS_SUCCESS:
      return { ...state, ticketApiRepsonse: action.payload };
    case type.CREATE_SUPPORT_REQUEST:
      return { ...state, adminTicket: null };
    case type.CREATE_SUPPORT_SUCCESS:
      return { ...state, adminTicket: action.payload };
    case type.GET_VENDOR_LIST_REQUEST:
      return { ...state, vendorList: null };
    case type.GET_VENDOR_LIST_SUCCESS:
      return { ...state, vendorList: action.payload };
    default:
      return state;
  }
};

export default SupportReducer;
