import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from "redux-saga/effects";
import { CREATE_SUPPORT_REQUEST, GET_TICKETS_REQUEST, GET_VENDOR_LIST_REQUEST } from "./type";
import { createSupportSuccess, getTicketsSuccess, getVenderListSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* supportReq({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/tickets", data);
    if (response.status) {
      yield put(getTicketsSuccess(response));
      if (!data.showMessages) {
        ApiResponseMessage(response.message, SUCCESS);
      }
    } else {
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* adminSupportReq({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/ticketAdmin", data.formData);
    if (response.status) {
      yield put(createSupportSuccess(response));
      if (!data.showMessages) {
        ApiResponseMessage(response.message, SUCCESS);
      }
    } else {
      yield put(createSupportSuccess(response));

      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* vendor() {
  try {
    const response = yield call(invokeApi, "get", "/vendorDropdownList");
    if (response.status) {
      yield put(getVenderListSuccess(response.data));
    } else {
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* watchSupportRequest() {
  yield takeEvery(GET_TICKETS_REQUEST, supportReq);
  yield takeEvery(CREATE_SUPPORT_REQUEST, adminSupportReq);
  yield takeEvery(GET_VENDOR_LIST_REQUEST, vendor);
}

function* SupportSaga() {
  yield all([fork(watchSupportRequest)]);
}

export default SupportSaga;
