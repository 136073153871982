import * as type from "./type";

export const getTicketsRequest = (data) => {
  return {
    type: type.GET_TICKETS_REQUEST,
    payload: data,
  };
};
export const getTicketsSuccess = (data) => {
  return {
    type: type.GET_TICKETS_SUCCESS,
    payload: data,
  };
};

export const getVenderListRequest = () => {
  return {
    type: type.GET_VENDOR_LIST_REQUEST,
  };
};
export const getVenderListSuccess = (data) => {
  return {
    type: type.GET_VENDOR_LIST_SUCCESS,
    payload: data,

  };
};

export const createSupportRequest = (data) => {
  return {
    type: type.CREATE_SUPPORT_REQUEST,
    payload: data,
  };
};
export const  createSupportSuccess = (data) => {
  return {
    type: type.CREATE_SUPPORT_SUCCESS,
    payload: data,
  };
};