import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import SupportTicket from "./SupportTicket";
import { AddSvg,  SettingSvg } from "../../../components/CommonSvg";
import {
  getColumesRequest,
  getStatusRequest,
  getSupportTicketRequest,
  setColumesRequest,
  syncSupportTicketsRequest,
  updateStatusRequest,
} from "../../../redux/admin/supportTickets/action";
import TableView from "../../../components/Common/TableView/TableWithPagination";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import SupportFilter from "./SupportFilter";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { formatDateToMMDDYYYY } from "../../../helper/DataTimeHelpers";
import SupportStoreView from "./SupportStoreView";
import ReactSelect from "react-select";
import { Button, Label } from "reactstrap";
import SettingView from "./Setting/SettingView";
import SettingTab from "./Setting/SettingTab";
function SupportList(props) {
  const {
    getSupportTicketRequest,
    SupportTicketList,
    setColumesRequest,
    totalTicket,
    getColumesRequest,
    colList,
    syncSupportTicketsRequest,
    getStatusRequest,
    statusList,
    updateStatusRequest,
    emailSetting,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  // const [modalView, setModaleView] = useState(false);
  const [settingView, setSettingView] = useState(false);
  const [exportFilterData, setExportFilterData] = useState(false);
  const [store, setStore] = useState("");
  const [category, setCategory] = useState("");
  const [priority, setPriority] = useState("");
  const [vendor, setVendor] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const handleModalToggle = () => {
  //   setModaleView(!modalView);

  // };
  const handleSettingsToggle = () => {
    setSettingView(!settingView);
  };

  const [selectedStore, setSelectedStore] = useState(null);

  const dispatchData = {
    searchText: searchData,
    endDate: endDate && formatDateToMMDDYYYY(endDate),
    startDate: startDate && formatDateToMMDDYYYY(startDate),
    hraMemberId: store?.value,
    category: category?.label,
    priority: priority?.label,
    vendor: vendor?.label,
    status: status?.label,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };
  useEffect(() => {
    getColumesRequest();
    getStatusRequest();
  }, []);
  useEffect(() => {
    getSupportTicketRequest(dispatchData);
    if (searchData || store || category || priority || vendor || status) {
      setCurrentPage(1)
    }
  }, [
    dataPerPage,
    currentPage,
    searchData,
    store,
    category,
    priority,
    vendor,
    status,
    endDate && startDate,
  ]);

  const [isFilter, setIsFilter] = useState(false);
  const handelShowFIlter = () => {
    setIsFilter(!isFilter);
  };
  useEffect(() => {
    if (isFilter) {
      setIsFilter(false);
    }
  }, [isFilter]);

  const fixCol = [
    {
      dataField: "ticketName",
      text: "Ticket Number",
      sort: true,
    },
    {
      dataField: "ownerName",
      text: "Store Owner Name",
      sort: true,
      formatter: (cell, row) => (
        <>
          {row.member?.firstName && row.member?.lastName
            ? `${row.member.firstName} ${row.member.lastName}`
            : "-"}
        </>
      ),
    },
  ];
  const [tableRef, setTableRef] = useState(true);
  useEffect(() => {
    if (colList?.columnsSelected >= 0) {
      setTableRef(false);
    }
    setTableRef(true);
  }, []);
  const [supportModal, setSupportModal] = useState(false);
  const handleOpenSupportModal = () => {
    setSupportModal(!supportModal);
  };
  const statusAndAction = [
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => (
        <>
          <span
            onClick={(e) => {
              if (e && e.stopPropagation) {
                e.stopPropagation();
              }
            }}
          >
            <ReactSelect
              type="text"
              id="Status"
              name="Status"
              className="form-control selectbox status-select"
              menuPlacement="auto"
              options={statusList?.length > 0 ? statusList : []}
              value={{ label: row.status, value: row.status }}
              onChange={async (selectedOption) => {

                const response = await commonConfirmBox(
                  "Are you sure you want to change the status?"
                );
                if (response) {
                  updateStatusRequest({
                    dispatchData: dispatchData,
                    status: {
                      ticketId: row.ticketId,
                      status: selectedOption.label,
                      hraMemberId: row.member.hraMemberId,
                      issue: row.issue,
                      vendor: row.vendor,
                      details: row.details,
                      issue: row.category,
                      priority: row.priority,
                      // subject: row?.subject,
                      description: row?.description,
                    },
                  });
                }
              }}
            />
            <Label className="form-label" htmlFor="assignStatus">
              Status
            </Label>
          </span>
        </>
      ),
    },
    {
      dataField: "Action",
      text: "",
      headerClasses: "action_col",
      formatter: (cell, row) => (
        <div className="action_col text-center">
          <Button
            size="sm"
            color="primary"
            data-tooltip-content={"Edit"}
            onClick={(e) => {

              if (e && e.stopPropagation) {
                setSelectedStore({
                  hraMemberId: row.hraMemberId,
                  firstName: row.member.firstName,
                  lastName: row.member.lastName,
                  storeNameRegular: row.store,
                  phoneNumberMobileNumber: row.member.phoneNumberMobileNumber,
                  emailAddress: row.member.emailAddress,
                  mailingStreetAddress: row.member.mailingStreetAddress,
                  city: row.member.city,
                  state: row.member.state,
                  mailingZip: row.member.mailingZip,
                  ...row,
                });
              }
              // handleEditModalToggle(row);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  const handleSyncToggle = async () => {
    const response = await commonConfirmBox(
      `Do you want to start support ticket sync?`
    );
    if (response) {
      syncSupportTicketsRequest(dispatchData);
    }
  };
  return (
    <>
      <div className="container">
        <TableHeader
          hedingName="Support Request"
          // searchBar={true}
          // addModalData={handleModalToggle}
          // addModalData={handleOpenSupportModal}
          // buttonNameModal={"Creat support ticket"}
          exportData={false}
          // setSearchData={setSearchData}
          updateData={() =>
            getSupportTicketRequest({
              searchText: "",
              pagination: {
                limit: dataPerPage,
                page: 1,
                orderKey: "createdDate",
                orderBy: "ASC",
              },
            })
          }
          settings={
            <>
              <button className="creat_btn btn btn-secondary" onClick={handleOpenSupportModal}>
                <AddSvg />Create support ticket
              </button>
              <button
                className="creat_btn btn btn-secondary"
                onClick={handleSettingsToggle}
              >
                <SettingSvg /> Display Settings
              </button>
              <button
                className="creat_btn btn btn-secondary"
                onClick={handleSyncToggle}
              >
                <SettingSvg /> Sync
              </button>
            </>
          }
          Filter={true}
          filterView={isFilter}
          filterBody={
            <SupportFilter
              handelShowFIlter={handelShowFIlter}
              setExportFilterData={setExportFilterData}
              useFilter={{
                store,
                setStore,
                category,
                setCategory,
                priority,
                setPriority,
                vendor,
                setVendor,
                status,
                setStatus,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
              }}
            />
          }
        />
      </div>
      {tableRef && (
        <TableView
          data={SupportTicketList ? SupportTicketList : []}
          columns={
            colList.columnsSelected
              ? [...fixCol, ...colList.columnsSelected, ...statusAndAction]
              : [...fixCol, ...statusAndAction]
          }
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          setDataPerPage={setDataPerPage}
          setCurrentPage={setCurrentPage}
          pagination={true}
          rowEvents={(e, row, rowIndex) => {
            setSelectedStore({
              hraMemberId: row.hraMemberId,
              firstName: row.member.firstName,
              lastName: row.member.lastName,
              storeNameRegular: row.store,
              phoneNumberMobileNumber: row.member.phoneNumberMobileNumber,
              emailAddress: row.member.emailAddress,
              mailingStreetAddress: row.member.mailingStreetAddress,
              city: row.member.city,
              state: row.member.state,
              mailingZip: row.member.mailingZip,
              ...row,
            });
          }}
          totalRecords={totalTicket}
          rowClassName={"cursor-pointer"}
        />
      )}

      {supportModal && (
        <CustomisedModal
          modalView={supportModal}
          customClass={"category-modal admin-support-ticket modal-xl"}
          modalName={"Create support ticket"}
          onModalDismiss={handleOpenSupportModal}
        >
          <SupportStoreView
            onModalDismiss={() => {
              setSupportModal(false);
            }}
            closeAndOpen={{ handleOpenSupportModal, setSelectedStore }}
          />
        </CustomisedModal>
      )}

      {selectedStore && (
        <CustomisedModal
          modalView={selectedStore}
          scrollable="true"
          customClass={
            "category-modal admin-support-ticket support-request-modal modal-lg "
          }
          modalName={
            <div className="ach_modal_header d-flex justify-content-between w-100">
              {selectedStore?.details
                ? "Edit support ticket"
                : "Create support ticket"}
              <span>{selectedStore.hraMemberId}</span>
            </div>
          }
          onModalDismiss={() => {
            setSelectedStore(false);
          }}
        >
          <SupportTicket
            onModalDismiss={() => {
              setSelectedStore(false);
            }}
            selectedStore={selectedStore}
            dispatchData={dispatchData}
          />
        </CustomisedModal>
      )}
      {settingView && (
        <CustomisedModal
          modalView={settingView}
          customClass={
            "category-modal admin-support-ticket support-request-modal modal-lg"
          }
          modalName={"Table column configuration"}
          onModalDismiss={handleSettingsToggle}
        >
          <SettingTab
            dispatchData={dispatchData}
            setColumesRequest={setColumesRequest}
            settingView={settingView}
            setSettingView={setSettingView}
            colList={colList}
            emailSettingData={emailSetting}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = ({ SupportTicket }) => {
  let SupportTicketList = [];
  let colList = [];
  let totalTicket = [];
  if (
    SupportTicket.getTickets?.list &&
    SupportTicket.getTickets?.list?.length > 0
  ) {
    SupportTicketList = SupportTicket.getTickets.list;
    totalTicket = SupportTicket.getTickets.totalRecords;
  }
  if (SupportTicket.getColoumn?.list) {
    colList = SupportTicket.getColoumn.list;
  }
  let statusList = [];
  if (
    SupportTicket.getStatus?.list &&
    SupportTicket.getStatus?.list?.length > 0
  ) {
    statusList = SupportTicket.getStatus.list.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }
  let emailSetting = SupportTicket.getTickets?.emailSettingData
    ? SupportTicket.getTickets.emailSettingData
    : null;
  return {
    SupportTicketList: SupportTicketList,
    totalTicket: totalTicket,
    colList: colList,
    statusList,
    emailSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportTicketRequest: (data) => dispatch(getSupportTicketRequest(data)),
    setColumesRequest: (data) => dispatch(setColumesRequest(data)),
    updateStatusRequest: (data) => dispatch(updateStatusRequest(data)),
    getColumesRequest: (data) => dispatch(getColumesRequest(data)),
    syncSupportTicketsRequest: (data) =>
      dispatch(syncSupportTicketsRequest(data)),
    getStatusRequest: () => dispatch(getStatusRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportList);
